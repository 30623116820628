import { useEffect, useState } from "react";
import Footer from "./Footer";
import Header from "./Header";

const Layout = ({ children }: { children: React.ReactNode }) => {

    const [theme, setTheme] = useState<'light' | 'dark'>('light');

    // Load theme from local storage on page load
    useEffect(() => {
        const savedTheme = localStorage.getItem('theme');
        if (savedTheme) {
            setTheme(savedTheme as 'light' | 'dark');
            document.documentElement.classList.toggle('dark', savedTheme === 'dark');
        }
    }, []);

    const toggleTheme = () => {
        const newTheme = theme === 'light' ? 'dark' : 'light';
        setTheme(newTheme);
        document.documentElement.classList.toggle('dark', newTheme === 'dark');
        localStorage.setItem('theme', newTheme); // Save theme to local storage
    };

    return (
        <main className={`fixed inset-y-0 left-0 w-full h-full overflow-hidden flex flex-col min-h-full max-h-full ${theme === 'dark' ? 'dark' : ''}`}>
            <Header theme={theme} toggleTheme={toggleTheme} />
            <div className='mx-auto container max-w-7xl grid-cols-1 lg:grid-cols-3 px-4 w-full'>
                {children}
            </div>
            <Footer />
        </main>
    );
}

export default Layout;