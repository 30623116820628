import { useState } from "react";

const Footer = () => {
    const [isHovered, setIsHovered] = useState(false);
    return (
        <footer className="footer mx-auto p-4 max-w-7xl w-full flex flex-row grow justify-between items-center flex-nowrap grid-cols-2 text-sm">
            <p className='max-w-48 text-xs md:text-sm md:max-w-none'>
                Made by <a href='https://rachel.fyi'>Rachel Cantor</a> who's available for contracting <a href="https://www.linkedin.com/in/rachelcantor">work</a>
            </p>
            <p>
                <a href='https://buymeacoffee.com/rachelfyi' className="footer flex items-center" onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
                    <span className="hidden md:inline">Buy me a </span>
                    <span>
                        <img
                            src={isHovered ? '/taiyaki-after.svg' : '/taiyaki-before.svg'}
                            alt='taiyaki'
                            className={`h-6 w-6 ml-1 transition-transform duration-300 ease-in-out transform ${isHovered ? 'rotate-45' : 'rotate-0'}`}
                        />
                    </span>
                </a>
            </p>
        </footer>
    );
}

export default Footer;