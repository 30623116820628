/* eslint-disable import/no-webpack-loader-syntax */
import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form'; // Import React Hook Form
import MarkdownWorker from 'worker-loader!./markdown.worker.ts'; // Web Worker import
import DOMPurify from 'dompurify'; // Sanitize in the main thread

interface WorkerResponse {
  formatted: string;
}

function App() {
  const [formattedContent, setFormattedContent] = useState<string>(''); // Formatted content from worker
  const [isPreviewVisible, setIsPreviewVisible] = useState<boolean>(false); // State to show/hide preview

  const { register, handleSubmit, formState: { isDirty }, watch } = useForm({
    defaultValues: { content: '' }
  });

  const content = watch("content");

  // When text is edited, reset the preview visibility
  useEffect(() => {
    if (isDirty) {
      setIsPreviewVisible(false); // Reset preview visibility when content is edited
    }
  }, [isDirty]);

  const handlePreview = () => {
    const worker = new MarkdownWorker();
    worker.postMessage({ content });

    worker.onmessage = (e: MessageEvent<WorkerResponse>) => {
      const { formatted } = e.data;
      const sanitizedContent = DOMPurify.sanitize(formatted);
      setFormattedContent(sanitizedContent);
      setIsPreviewVisible(true); // Set preview visible after processing
      worker.terminate(); // Terminate after completion
    };
  };

  const handleCopy = async () => {
    if (formattedContent) {
      const blob = new Blob([formattedContent], { type: 'text/html' });
      const data = [new ClipboardItem({ 'text/html': blob })];
      await navigator.clipboard.write(data);
      alert('Formatted content copied to clipboard!');
    }
  };

  return (
    <div className="grid grid-cols-1 gap-4 md:grid-cols-12 h-full">
      <div className="col-span-12 md:col-span-5">
        <div className='flex flex-col'>
          <label htmlFor="content" className="label">
            Chat GPT Output
          </label>
          <textarea
            {...register("content", { required: true })}
            className="input-output input" // Constrain height
            placeholder="Paste your ChatGPT output here..."
          />
        </div>
      </div>

      <div className="col-span-12 md:col-span-2 flex flex-row md:flex-col items-center justify-center">
        <button
          className='button'
          onClick={handleSubmit(handlePreview)}
          disabled={!isDirty}
        >
          Preview
        </button>

        <button
          className='button'
          onClick={handleCopy}
          disabled={!isPreviewVisible || !isDirty} // Disable until preview is visible
        >
          Copy
        </button>

      </div>
      <div className="col-span-12 md:col-span-5">

        <div className='flex flex-col'>
          <label className="label">
            Google Docs Input
          </label>
          <div className='input-output output'>
            {!isPreviewVisible && (
              <div className="">
                {isDirty ? "Press Preview to continue" : "Enter text in the Chat GPT Output to continue"}
              </div>
            )}

            {isPreviewVisible && (
              <div className="flex-grow overflow-auto" dangerouslySetInnerHTML={{ __html: formattedContent }} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;