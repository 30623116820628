import { Switch } from "@headlessui/react";

const Header = ({ theme, toggleTheme }: { theme: string, toggleTheme: () => void }) => {

    return (
        <header className="text-center text-xl font-semibold shrink-0">
            <nav aria-label='Global' className="mx-auto flex px-4 py-2 max-w-7xl items-center justify-between">
                <a href="https://tidytext.cc" className="bg-light dark:bg-dark bg-contain bg-no-repeat w-20 h-20 hover:rotate-12 hover:scale-105">
                    <span className="sr-only">TidyText.cc</span>
                </a>
                {/* Dark Mode Toggle (HeadlessUI Switch with Icons) */}
                <div className="flex justify-center mb-4">
                    <Switch
                        checked={theme === 'dark'}
                        onChange={toggleTheme}
                        className="group relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-secondary-200 transition-all ease-in-out focus:outline-none ring-2 ring-secondary-200 ring-offset-2 data-[checked]:bg-blue-700 data-[checked]:ring-secondary-500"
                    >
                        <span className="sr-only">Use dark mode</span>
                        <span className="pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5 group-data-[checked]:bg-primary">
                            <span
                                aria-hidden="true"
                                className="absolute inset-0 flex h-full w-full items-center justify-center transition-opacity duration-200 ease-in group-data-[checked]:opacity-0 group-data-[checked]:duration-100 group-data-[checked]:ease-out"
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="text-primary h-3 w-3">
                                    <path strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" d="M12 2.25a.75.75 0 0 1 .75.75v2.25a.75.75 0 0 1-1.5 0V3a.75.75 0 0 1 .75-.75ZM7.5 12a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0ZM18.894 6.166a.75.75 0 0 0-1.06-1.06l-1.591 1.59a.75.75 0 1 0 1.06 1.061l1.591-1.59ZM21.75 12a.75.75 0 0 1-.75.75h-2.25a.75.75 0 0 1 0-1.5H21a.75.75 0 0 1 .75.75ZM17.834 18.894a.75.75 0 0 0 1.06-1.06l-1.59-1.591a.75.75 0 1 0-1.061 1.06l1.59 1.591ZM12 18a.75.75 0 0 1 .75.75V21a.75.75 0 0 1-1.5 0v-2.25A.75.75 0 0 1 12 18ZM7.758 17.303a.75.75 0 0 0-1.061-1.06l-1.591 1.59a.75.75 0 0 0 1.06 1.061l1.591-1.59ZM6 12a.75.75 0 0 1-.75.75H3a.75.75 0 0 1 0-1.5h2.25A.75.75 0 0 1 6 12ZM6.697 7.757a.75.75 0 0 0 1.06-1.06l-1.59-1.591a.75.75 0 0 0-1.061 1.06l1.59 1.591Z" />
                                </svg>
                            </span>
                            <span
                                aria-hidden="true"
                                className="absolute inset-0 flex h-full w-full items-center justify-center opacity-0 transition-opacity duration-100 ease-out group-data-[checked]:opacity-100 group-data-[checked]:duration-200 group-data-[checked]:ease-in"
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="text-white w-3 h-3">
                                    <path fillRule="evenodd" d="M9.528 1.718a.75.75 0 0 1 .162.819A8.97 8.97 0 0 0 9 6a9 9 0 0 0 9 9 8.97 8.97 0 0 0 3.463-.69.75.75 0 0 1 .981.98 10.503 10.503 0 0 1-9.694 6.46c-5.799 0-10.5-4.7-10.5-10.5 0-4.368 2.667-8.112 6.46-9.694a.75.75 0 0 1 .818.162Z" clipRule="evenodd" />
                                </svg>
                            </span>
                        </span>
                    </Switch>
                </div>
            </nav>
        </header>
    );
}

export default Header;